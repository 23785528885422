import './contextmenu.css';
export default class Contextmenu {
  constructor(target, menu) {
    this.id = 'contextmenuContainer';
    this.target = target;
    this.menu = menu;
    if (!this.getContainer()) {
      this.createContainer();
    }
    this.init();
  }

  init() {
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    // 注册右键菜单事件
    window.addEventListener('contextmenu', this.show);
    // 注册点击其他地方取消菜单事件
    window.addEventListener('click', this.hide);
  }

  createContainer() {
    const el = document.createElement('div');
    el.id = this.id;
    el.style.position = 'fixed';
    el.style.backgroundColor = '#fff';
    el.style.boxShadow = '0 2px 12px 2px rgba(0, 0, 0, 0.1)';
    el.style.zIndex = 100;
    el.style.borderRadius = '3px';
    el.style.display = 'none';
    document.body.appendChild(el);
  }

  getContainer() {
    return document.getElementById(this.id);
  }

  removeContainer() {
    const container = this.getContainer();
    if (container) {
      document.body.removeChild(container);
    }
  }

  show(e) {
    e.preventDefault();
    const isClickedInsideTarget = this.target.contains(e.target);
    if (!isClickedInsideTarget) {
      this.hide();
      return;
    }
    this.removeMenu();
    this.appendMenu();
    const parent = this.getContainer();
    parent.style.display = 'block';
    parent.style.left = e.clientX + 'px';
    parent.style.top = e.clientY + 'px';
    this.menu.style.display = 'block';
  }

  hide(e) {
    console.log('hide');
    this.removeMenu();
    const parent = this.getContainer();
    parent.style.display = 'none';
  }

  appendMenu() {
    const parent = this.getContainer();
    parent.appendChild(this.menu);
  }

  removeMenu() {
    const parent = this.getContainer();
    if (parent && parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  destroy() {
    window.removeEventListener('contextmenu', this.show);
    window.removeEventListener('click', this.hide);
    this.removeContainer();
  }
}
